<template>
<div class="bg--info">
  <header-block cls=" header__menu--info"/>
  <div class="container">
    <div class="info">
      <h1 class="title__info">
        Ваш язык любви:
      </h1>
      <p class="description">
        Ключ к тесту «5 языков любви»:
      </p>
      <div class="info-table">
        <div class="info-table__block">
          <div class="info-table__desc">
            Язык любви
          </div>
          <div class="info-table__num">
            <span>a</span>
            <div class="info-table__mob">
              <p class="info-table__mob-point">
                {{ $route.query.a }} {{ declOfNum($route.query.a, ['балл', 'балла', 'баллов']) }}
              </p>
              <p class="info-table__mob-txt">
                Слова поощрения и одобрения
              </p>
            </div>
          </div>
          <div class="info-table__num">
            <span>b</span>
            <div class="info-table__mob">
              <p class="info-table__mob-point">
                {{ $route.query.b }} {{ declOfNum($route.query.b, ['балл', 'балла', 'баллов']) }}
              </p>
              <p class="info-table__mob-txt">
                Качественное время вместе, Безраздельное внимание
              </p>
            </div>
          </div>
          <div class="info-table__num">
            <span>c</span>
            <div class="info-table__mob">
              <p class="info-table__mob-point">
                {{ $route.query.c }} {{ declOfNum($route.query.c, ['балл', 'балла', 'баллов']) }}
              </p>
              <p class="info-table__mob-txt">
                Подарки
              </p>
            </div>
          </div>
          <div class="info-table__num">
            <span>d</span>
            <div class="info-table__mob">
              <p class="info-table__mob-point">
                {{ $route.query.d }} {{ declOfNum($route.query.d, ['балл', 'балла', 'баллов']) }}
              </p>
              <p class="info-table__mob-txt">
                Помощь, язык служения
              </p>
            </div>
          </div>
          <div class="info-table__num">
            <span>e</span>
            <div class="info-table__mob">
              <p class="info-table__mob-point">
                {{ $route.query.e }} {{ declOfNum($route.query.e, ['балл', 'балла', 'баллов']) }}
              </p>
              <p class="info-table__mob-txt">
                Прикосновения, Физический контакт
              </p>
            </div>
          </div>
        </div>
        <div class="info-table__block info-table__block--mid">
          <div class="info-table__txt"></div>
          <div class="info-table__txt">
            Слова поощрения и одобрения
          </div>
          <div class="info-table__txt">
            Качественное время вместе, Безраздельное внимание
          </div>
          <div class="info-table__txt">
            Подарки
          </div>
          <div class="info-table__txt">
            Помощь, язык служения
          </div>
          <div class="info-table__txt">
            Прикосновения, Физический контакт
          </div>
        </div>
        <div class="info-table__block info-table__block--bot">
          <div class="info-table__desc">
            Кол-во баллов
          </div>
          <div class="info-table__num">
            {{ $route.query.a }}
          </div>
          <div class="info-table__num">
            {{ $route.query.b }}
          </div>
          <div class="info-table__num">
            {{ $route.query.c }}
          </div>
          <div class="info-table__num">
            {{ $route.query.d }}
          </div>
          <div class="info-table__num">
            {{ $route.query.e }}
          </div>
        </div>
      </div>
      <div class="info__blocks">
        <div class="info__block">
          <p class="description">
            А. Язык слов одобрения, поощрения и похвалы.
          </p>
          <p class="txt">
            Комплименты, слова похвалы, благодарности, одобрения...
            Очень часто во время продолжительной семейной жизни все
            комплименты и просьбы превращаются в критические замечания
            и требования. Если для вашего партнера это ведущий язык, то
            требованиями и критикой вы приносите для него очень сильную боль
            и разрушаете любовные отношения. Если вам нужно покритиковать человека,
            у которого ключевой язык любви – это слова ободрения и поощрения – сделайте
            «бутерброд» из слов: сверху положите слова похвалы и поддержки, посредине можно
            спрятать немного конструктивной критики и накрыть все это еще одной порцией похвалы,
            ободрения и одобрения.
          </p>
        </div>
        <div class="info__block">
          <p class="description">
            B. Язык качественного времени вместе (безраздельное внимание).
          </p>
          <p class="txt">
            Качественное время – это время близости, время диалога,
            совместной деятельности, возможность поделиться мыслями,
            чувствами, переживаниями, впечатлениями. Подумайте, сколько
            значимого времени вы проводите вместе с близкими для вас
            людьми: в день / в неделю / в месяц? Возможно, за огромным
            количеством друзей, коллег по работе или вузу вы забыли уделять
            это время вашим родителям или родственникам? Сегодня самое время
            возобновить это качественное время вместе всей семьей!
          </p>
        </div>
        <div class="info__block">
          <p class="description">
            С. Язык подарков.
          </p>
          <p class="txt">
            Подарок не обязательно должен быть дорогим, его
            всегда можно сделать своими руками, главное – то,
            что вы помнили о любимом и близком вам человеке, думали
            о нем, и теперь выражаете в подарке свою любовь.
            Подарки – это видимые символы вашей любви. Если вы видите,
            что близкий вам человек говорит на этом языке – спросите,
            какие именно подарки он / она ценит и почему это важно для них.
            Спросите: «что ты чувствуешь, когда я дарю тебе подарок?» и
            вы увидите, что это – любовь!
          </p>
        </div>
        <div class="info__block">
          <p class="description">
            D. Язык помощи в делах и служения.
          </p>
          <p class="txt">
            Служение – это особая форма выражения любви,
            когда ради другого человека я выполняю определенную
            работу, «служу» ему, помогая делать или даже делая
            вместо моего близкого его/ее часть работы. Помощь
            в уборке дома, мытье посуды, поход в магазин – все
            это служение, которое важно для человека, у которого
            язык любви – это помощь в делах.
          </p>
        </div>
        <div class="info__block">
          <p class="description">
            E. Язык прикосновений.
          </p>
          <p class="txt">
            Прикосновения, объятия, поцелуи – тот язык,
            который знаком каждому человеку. Физические
            касания жизненно важны для любого человека.
            Дети из дома малютки, имея такие же условия
            для роста, что и их сверстники в семьях, отстают
            в развитии только из-за отсутствия физического контакта.
            Язык прикосновений обладает огромной силой особенно для тех
            людей, у кого это основной язык любви. Дружеские похлопывания
            по плечу, поглаживания по голове и объятия сделают для этих людей
            чудеса и наполнят их сосуд любви!
          </p>
        </div>
      </div>
      <p class="description">
        Какой их языков любви набрал больше всего баллов? Это
        и есть ваш основной язык любви. Если два языка набрали одинаковое
        количество баллов – значит вы «двуязычны».
      </p>
      <img src="@/assets/images/love/start.png"
        srcset="@/assets/images/love/start@2x.png 2x" alt="img" class="info__img">
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'secret-self',
  methods: {
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];
      const i = (number % 100 > 4 && number % 100 < 20)
        ? 2 : cases[(number % 10 < 5) ? number % 10 : 5];
      return titles[i];
    },
  },
};
</script>
