export default {
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/guide/video', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/login');
      } else {
        this.slots = response.data.result;
      }
    },
  },
};
